<template>
  <div>
    <h4>{{ i18n['profile-dues-info'].tcMembershipRenewal }}</h4>
    <div class="specs">
      <p>
        <strong>{{ i18n['profile-dues-info'].tcJoinDate }}:</strong>
        {{ formatDate(profile.join_date) }}
      </p>
      <p>
        <strong>{{ i18n['profile-dues-info'].tcMembershipRenewed }}:</strong>
        {{ profile.paid_flag ? i18n['profile-dues-info'].tcYes : i18n['profile-dues-info'].tcNo }}
      </p>
      <p v-if="!!profile.paid_date">
        <strong>{{ i18n['profile-dues-info'].tcMembershipRenewedDate }}:</strong>
        {{ formatDate(profile.paid_date) }}
      </p>
      <router-link v-if='showRenewalButton' :to="{ name: 'member-dues',params:{m:'link'}}" class='btn btn-primary'>
        {{ i18n['profile-dues-info'].tcRenewMembership }}
      </router-link>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import constantData from '@/json/data.json'
import { mapGetters } from 'vuex'

export default {
  name: 'profile-dues-info',
  props: {
    i18n: {
      type: Object,
      default: () => {
        return {
          'profile-dues-info': {
            tcCampName: 'Camp Name',
            tcChurchMembership: 'Church Membership',
            tcMemberDetails: 'Member Details',
            tcMemberID: 'Member ID',
            tcOtherInformation: 'Other Information',
            tcViewMore: 'View More',
          },
        }
      },
    },
    profile: {
      type: Object,
      default: () => {
        return {
          join_date: '',
          paid_date: '',
          paid_flag: false,
          member_type_key: null
        }
      },
    },
  },
  data() {
    return {}
  },
  methods: {
    formatDate(thisDate) {
      if (!thisDate) {
        return ''
      }
      return thisDate.substring(0, 10)
    },
  },
  created() {},
  computed: {
    ...mapGetters({
               userLogin: 'user/userLogin',
      paymentInfo: 'membership/paymentInfo',
    }),
    showRenewalButton() {
      return !!constantData.USA_membership_types[this.profile.member_type] && this.userLogin.usa_dues_season && !this.profile.paid_flag
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/settings.scss';
@import '@/styles/bootstrap/b-button.scss';
@import '@/styles/bootstrap/b-button-group.scss';
</style>
