<template>
  <div class="church-details template-2">
    <header class="page-header container mx-lg d-flex">
      <h1>{{ translations.tcMemberProfile }}</h1>
    </header>
    <div class="page-body container mx-lg">
      <div class="details">
        <div class="d-flex row primary">
          <div class="col">
            <profile-contact-info :profile="individualProfile" @reloadProfile="pageLoad()"> </profile-contact-info>
          </div>
          <div class="col">
            <profile-member-info :profile="individualProfile" :i18n="translations.components"> </profile-member-info>
          </div>
          <div class="col">
            <profile-spouse-info class="mb-3" v-if="!individualProfile.widow_flag" :profile="individualProfile"
              :isAbleToAddSpouse="okToEditProfile || iCanSee(secured_controls.member_add_spouse_button)"
              @changeIndividual="pageLoad()">
            </profile-spouse-info>
            <profile-dues-info :profile="individualProfile" :i18n="translations.components"></profile-dues-info>
          </div>
        </div>
        <div class="row d-flex events" v-if="translations.components">
          <div class="col col-4" v-if="isGideon">
            <app-event :obj="churchSpeakerObj" :i18n="translations.components"></app-event>
          </div>
          <div class="col col-4">
            <app-event :obj="pastorEventSpeakerObj" :i18n="translations.components"></app-event>
          </div>
          <div class="col col-4">
            <app-event :obj="membershipDinnerSpeakerObj" :i18n="translations.components"></app-event>
          </div>
        </div>
        <div class="d-flex">
          <a download class="btn btn-primary mr-4"
            @click.prevent="downloadReport(memberProfileReportAsPDF, 'MemberDetailReport', 'pdf')">{{
              translations.tcRunDetailedMemberReport }}</a>
        </div>
      </div>
      <div class="actions">
        <div class="card-deck row">
          <div class="card card-secondary col">
            <div :style="{ height: '4px' }"></div>
            <svg-contact-information></svg-contact-information>
            <div class="card-body">
              <h5 class="card-title">{{ translations.tcMemberCommunicationsTitle }}</h5>
              <p class="card-text text-left">
                {{ translations.tcMemberCommunicationsDescription }}
              </p>
              <b-button to="/programs/mb/communications/member" variant="primary">{{
                translations.tcViewMore
              }}</b-button>
            </div>
          </div>
          <div class="card card-secondary col">
            <div :style="{ height: '5px' }"></div>
            <svgChurchOfficer></svgChurchOfficer>
            <div class="card-body">
              <h5 class="card-title">{{ translations.tcMemberDetailsTitle }}</h5>
              <p class="card-text text-left">
                {{ translations.tcMemberDetailsDescription }}
              </p>
              <b-button to="/programs/mb/member-details" variant="primary">
                {{ translations.tcViewMore }}
              </b-button>
            </div>
          </div>
          <div class="card card-secondary col" v-if="okToEditProfile === true &&
            userLogin.membertype.toLowerCase() === 'usa' &&
            (individualProfile.member_type === 'Gideon Member' ||
              individualProfile.member_type === 'Auxiliary Member') &&
            !isIndividualProfileMemberLifetime
            ">
            <div :style="{ height: '0' }"></div>
            <svg-payment-center></svg-payment-center>
            <div class="card-body">
              <h5 class="card-title">{{ translations.tcPaymentCenterTitle }}</h5>
              <p class="card-text text-left">
                {{ translations.tcPaymentCenterDescription }}
              </p>
              <b-button :to="{ name: 'member-payment-center' }" variant="primary">{{
                translations.tcViewMore
              }}</b-button>
            </div>
          </div>
        </div>
        <div class="card-deck row">
          <div class="card card-secondary col" v-if="okToEditProfile || okToViewPreferencesTile">
            <div :style="{ height: '11px' }"></div>
            <svg-member-communication-preferences></svg-member-communication-preferences>
            <div class="card-body">
              <h5 class="card-title">{{ translations.tcUserPreferencesTitle }}</h5>
              <p class="card-text text-left">
                {{ translations.tcUserPreferencesDescription }}
              </p>
              <b-button :to="{ name: 'user-preferences' }" variant="primary">
                {{ translations.tcViewMore }}
              </b-button>
            </div>
          </div>
          <div class="card card-secondary col">
            <div :style="{ height: '0' }"></div>
            <svg-member-roles></svg-member-roles>
            <div class="card-body">
              <h5 class="card-title">{{ translations.tcMemberRolesTitle }}</h5>
              <p class="card-text text-left">
                {{ translations.tcMemberRolesDescription }}
              </p>
              <b-button :to="{ name: 'member-roles' }" variant="primary">
                {{ translations.tcViewMore }}
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import ChurchOfficer from '@/assets/svgs/church-officers.vue'
import ContactInformation from '@/assets/svgs/contact-information.vue'
import date_data from '@/json/date.json'
import Event from '@/components/Event.vue'
import MemberCommunicationPreferences from '@/assets/svgs/member-communication-preferences.vue'
import MemberRoles from '@/assets/svgs/member-roles.vue'
import PaymentCenter from '@/assets/svgs/payment-center.vue'
import ProfileContactInfo from '@/components/ProfileContactInfo.vue'
import ProfileDuesInfo from '@/components/ProfileDuesInfo'
import ProfileMemberInfo from '@/components/ProfileMemberInfo'
import ProfileSpouseInfo from '@/components/ProfileSpouseInfo'
import { reportDownloadMixin } from '@/mixins/reportDownloadMixin'
import { securityMixin } from '@/mixins/securityMixin'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'member-profile',
  mixins: [securityMixin, translationMixin, reportDownloadMixin],
  data() {
    return {
      okToViewPreferencesTile: false,
      translations: {},
      secured_controls: {
        member_add_spouse_button: 'c0773589-8af4-4101-99b0-b8bd07206b36',
        view_member_preferences_tile: '93ce2b71-c7c9-4535-ae45-83b9c5d30e01'
      }
    }
  },
  components: {
    appEvent: Event,
    profileContactInfo: ProfileContactInfo,
    profileDuesInfo: ProfileDuesInfo,
    profileMemberInfo: ProfileMemberInfo,
    profileSpouseInfo: ProfileSpouseInfo,
    svgChurchOfficer: ChurchOfficer,
    svgContactInformation: ContactInformation,
    svgMemberCommunicationPreferences: MemberCommunicationPreferences,
    svgMemberRoles: MemberRoles,
    svgPaymentCenter: PaymentCenter
  },
  methods: {
    ...mapActions({
      getControlPermissions: 'user/getControlPermissions',
      loadIndividualProfile: 'membership/loadIndividualProfile',
      loadMemberProfileReportUrls: 'membership/loadMemberProfileReportUrls',
      loadMembersByCamp: 'membership/loadMembersByCamp',
      resetAvatarURL: 'membership/resetAvatar',
      setIsIndividualProfileLifeTime: 'membership/setIsIndividualProfileLifeTime',
      setLoadingStatus: 'menu/setLoadingStatus'
    }),
    async pageLoad() {
      await Promise.all([
        await this.loadIndividualProfile(),
        await this.loadMemberProfileReportUrls(),
        await this.checkMemberType(),
        await this.checkPermissions(),
        await this.setIsIndividualProfileLifeTime()
      ]).then(() => {
        this.resetAvatarURL(this.individualProfile.image_url_path)
        this.setLoadingStatus(false)
      })
    },
    async checkPermissions() {
      await this.getControlPermissions(this.secured_controls.view_member_preferences_tile).then(() => {
        this.okToViewPreferencesTile = this.determineAccessibility(
          this.secured_controls.view_member_preferences_tile,
          true
        )
      })
    },
    async checkMemberType() {
      let hasMemberTypeKey = !!this.individualProfile.member_type_key
      let hasProspectTypeKey = !!this.individualProfile.prospect_met_key
      if (!hasProspectTypeKey && !hasMemberTypeKey) {
        this.$router.push('/programs/mb/non-member-profile-mb')
      }
      if (hasProspectTypeKey && !hasMemberTypeKey) {
        this.$router.push('/programs/mb/prospect-profile')
      }
    },
    speakerObject(qdate, qtitle, qflag, mflag) {
      var qDate = !!qdate ? new Date(qdate) : null
      var obj = {
        title: qtitle,
        month: !!qDate ? this.months[qDate.getMonth()] : '',
        day: !!qDate ? qDate.getDate() : '',
        year: !!qDate ? qDate.getFullYear() : '',
        fullDate: !!qDate ? qDate : '',
        qualifiedFlag: qflag ? 'Yes' : 'No',
        mediaFlag: !!mflag ? (mflag ? 'Yes' : 'No') : null
      }
      return obj
    }
  },
  computed: {
    ...mapGetters({
      iCanSee: 'user/iCanSee',
      individualProfile: 'membership/individualProfile',
      isIndividualProfileMemberLifetime: 'membership/isIndividualProfileMemberLifetime',
      memberProfileReportAsPDF: 'membership/memberProfileReportAsPDF',
      members: 'membership/members',
      officerToolbarSelected: 'user/officerToolbarSelected',
      okToEditProfile: 'user/okToEditProfile',
      prefCulture: 'user/userPreferredCulture',
      userLogin: 'user/userLogin',
      userSelectedIndividualKey: 'user/userSelectedIndividualKey'
    }),
    months() {
      return date_data.months.map(dm => dm.abbrlow)
    },
    churchSpeakerObj() {
      return this.speakerObject(
        this.individualProfile.cp_qualified_date,
        this.translations.tcChurchSpeaker,
        this.individualProfile.cp_qualified_flag,
        this.individualProfile.cp_media_flag
      )
    },
    pastorEventSpeakerObj() {
      return this.speakerObject(
        this.individualProfile.bs_qualified_date,
        this.translations.tcPastorEventSpeaker,
        this.individualProfile.bs_qualified_flag
      )
    },
    membershipDinnerSpeakerObj() {
      return this.speakerObject(
        this.individualProfile.ds_qualified_date,
        this.translations.tcMembershipDinnerSpeaker,
        this.individualProfile.ds_qualified_flag
      )
    },
    isGideon() {
      return !!this.individualProfile.member_type
        ? this.individualProfile.member_type.toString().includes('Gideon')
        : false
    }
  },
  async created() {
    //need a selected individual or redirect back to membership landing

    if (!this.userSelectedIndividualKey) {
      this.$router.push('/programs/mb/membership')
    }

    try {
      this.setLoadingStatus(true)
      await Promise.all([
        this.getViewTranslations(),
        this.getComponentTranslations('event', 'profile-member-info', 'profile-dues-info')
      ]).then(async results => {
        const translatedText = { ...results[1] }
        this.$set(this.translations, 'components', translatedText)
        await this.pageLoad()
      })
    } catch (e) {
      console.error('Error in MemberProfile.vue, created()', e)
    }
  },
  mounted() {
    this.$root.$on('member-profile-reload', data => {
      Promise.all([this.getViewTranslations(), this.getComponentTranslations('event'), this.pageLoad()]).then(
        results => {
          const componentTranslations = results[1]
          this.$set(this.translations, 'components', componentTranslations)
        }
      )
    })
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/settings.scss';
@import '@/styles/bootstrap/b-button.scss';
@import '@/styles/bootstrap/b-button-group.scss';

.col-3.date {
  min-height: 118px;
}

.church-details {
  form {
    .quick-jump {
      min-width: 201px;
    }
  }
}

.church_image {
  min-width: 251px;
  min-height: 185px;
}

.details {
  margin-bottom: 46px;
  padding: 32px 40px 40px;
  background-color: #fff;
  box-shadow: 0 9px 24px 6px rgba(197, 197, 197, 0.53);

  .d-flex {
    @include breakpoint(sm) {
      flex-wrap: wrap;

      >* {
        margin-bottom: 2rem;
        margin-right: 0 !important;
        width: 100%;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

.primary {
  margin-bottom: 35px;

  >.col {
    @include breakpoint(sm) {
      flex: 0 0 auto;
      width: 100%;
    }
  }

  h5 {
    margin: 0;
    color: #1e68fb;
    font-size: 19px;
    font-weight: 700;
    letter-spacing: 1.03px;
    line-height: 26px;
  }

  strong {
    font-weight: 800;
    text-transform: uppercase;
  }

  p {
    margin-bottom: 17px;
    font-weight: 600;
    letter-spacing: 1.03px;
    line-height: 26px;
  }

  img {
    margin-top: 17px;
  }

  a {
    color: rgba(30, 104, 251, 1);
    text-decoration: underline;

    &:hover {
      color: rgba(30, 104, 251, 0.8);
    }
  }

  .btn {
    color: #fff;
    text-decoration: none;
  }

  img {
    margin: 0;
  }

  .specs {
    p {
      margin-bottom: 10px;
      line-height: 22px;

      &:last-of-type {
        margin-bottom: 17px;
      }
    }
  }

  .contact {
    strong {
      font-weight: 700;
      text-transform: capitalize;
    }
  }
}

.events {
  margin-bottom: 50px;

  .col-4 {

    // &:nth-of-type(even) {
    //   .event .date {
    //     background-image: linear-gradient(135deg, #0098c7 0%, #92cfeb 100%);
    //     background-image: linear-gradient(
    //       135deg,
    //       hsl(194, 100%, 39%) 0%,
    //       hsl(198, 68%, 75%) 100%
    //     );
    //   }
    // }
    @include breakpoint(sm) {
      max-width: 100%;
      width: 100%;
      flex: 0 0 auto;

      &:last-of-type .event {
        margin-bottom: 0;
      }
    }
  }

  .event {
    margin-bottom: 0;
    border: 1px solid #e9e9e9;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);

    h3 {
      color: $teal-800;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 22px;
    }
  }
}

.actions {
  @include breakpoint(sm) {
    padding: 0 1rem;
  }
}

.card-deck {
  .card {
    svg {
      display: block;
      margin: 0 auto 13px;
    }
  }
}

.card-deck.row {
  margin-bottom: 30px;

  @include breakpoint(mobile) {
    display: block;
  }
}
</style>
